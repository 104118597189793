<template>
    <div class="landing-container">
        <img class="landing-container__logo" src="../assets/logo.webp" />

        <div>
            <div class="landing-container__title">
                Elevate Love Chatting<br />to New Heights!
            </div>
            <div class="landing-container__subtitle">
                Meet your personal AI flirt assistant:
            </div>

            <div
                v-for="(item, index) in manual"
                :key="`manual${index}`"
                class="landing-container__manual"
            >
                <div>{{ index + 1 }}. {{ item }}</div>
            </div>
        </div>

        <img class="landing-container__img" src="../assets/couple.webp" />
        <button class="landing-container__button" @click="openLink">
            Go to App Store
        </button>
    </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    name: 'LandingPage',
    data: () => {
        return {
            manual: [
                'Download MatchAI.',
                'Use it while chatting and beyond.',
                'Double your chances of finding true love (with less effort)!',
            ],
        }
    },
    methods: {
        getURLParameter(name) {
            const params = new URLSearchParams(window.location.search)

            return params.get(name)
        },
        buildAdjustLink() {
            const campaignName = this.getURLParameter('campaign_name')
            const campaignId = this.getURLParameter('campaign_id')
            const adsetName = this.getURLParameter('adset_name')
            const adsetId = this.getURLParameter('adset_id')
            const adName = this.getURLParameter('ad_name')
            const adId = this.getURLParameter('ad_id')
            const fbclid =
                this.getURLParameter('fbclid') ||
                this.getURLParameter('fbc') ||
                this.getURLParameter('_fbc') ||
                Cookies.get('_fbc')
            const fbpid =
                this.getURLParameter('fbpid') ||
                this.getURLParameter('fbp') ||
                this.getURLParameter('_fbp') ||
                Cookies.get('_fbp')

            let newParams = new URLSearchParams()
            if (campaignName && campaignId) {
                newParams.append('campaign', `${campaignName} (${campaignId})`)
            }
            if (adsetName && adsetId) {
                newParams.append('adgroup', `${adsetName} (${adsetId})`)
            }
            if (adName && adId) {
                newParams.append('creative', `${adName} (${adId})`)
            }
            if (fbclid) {
                newParams.append('fbclid', fbclid)
            }
            if (fbpid) {
                newParams.append('fbpid', fbpid)
            }
            
            return `https://app.adjust.com/1bwwif3a?${newParams.toString()}`
        },
        openLink() {
            let link = this.buildAdjustLink()
            window.open(link, '_blank')
        },
    },
}
</script>

<style scoped>
.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    width: 100%;
    max-width: 390px;
    padding: 20px;

    background-image: url('../assets/bg-sm.webp');
    background-position: center bottom;
    background-repeat: no-repeat;

    color: var(--typography-color);
}

.landing-container__logo {
    width: 154px;
    height: 105px;
    margin-top: -20px;
}

.landing-container__title {
    margin-bottom: 20px;

    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    font-family: Signika;
}

.landing-container__subtitle {
    margin-bottom: 20px;

    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
}

.landing-container__manual {
    margin-bottom: 10px;

    text-align: start;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.landing-container__img {
    display: none;

    height: auto;
    max-width: 100%;
    margin-top: 30px;
}

.landing-container__button {
    min-width: 100%;
    height: 64px;
    margin-top: auto;

    border: none;
    border-radius: 20px;

    box-shadow: 0px 0px 29.6px 0px #460357;
    background: linear-gradient(
        178.28deg,
        #f48f96 -12.12%,
        #fe22a6 51.36%,
        #ec1749 109.95%
    );

    cursor: pointer;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: var(--typography-color);
    font-family: Signika;
}

@media screen and (min-width: 900px) {
    .landing-container {
        max-width: 454px;
        background-image: none;
    }

    .landing-container__title {
        font-size: 42px;
    }

    .landing-container__img {
        display: block;
    }

    .landing-container__button {
        margin-top: 30px;
    }
}
</style>

<template>
    <div class="wrapper">
        <Landing />
    </div>
</template>

<script>
import Landing from './components/Landing.vue'

export default {
    name: 'App',
    components: {
        Landing,
    },
}
</script>

<style>
:root {
    --background-color: #000000;
    --typography-color: #ffffff;
}

* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Open Sans';
}

.wrapper {
    display: flex;
    justify-content: center;

    min-height: 100dvh;

    background-color: var(--background-color);
}
</style>
